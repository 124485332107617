import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updatePassword,
} from 'firebase/auth';
import { auth } from './firebase';

export const doCreateUserEmailPassword = async (email, pass) => {
  return createUserWithEmailAndPassword(auth, email, pass);
};

export const doSignInWithEmailAndPassword = (email, pass) => {
  return signInWithEmailAndPassword(auth, email, pass);
};
export const doSignOut = () => {
  return auth.signOut();
};

export const doPasswordReset = (email) => {
  return sendPasswordResetEmail(auth, email, {
    url: 'https://registro-ganacongonher.com/'
  });
};

export const doPasswordChange = (pass) => {
  return updatePassword(auth.currentUser, pass);
};

export const doSendEmailVerification = () => {
  return sendEmailVerification(auth.currentUser, {
    url: `${window.location.origin}/`,
  });
};
