import {
  Button,
  Card,
  ConfigProvider,
  Input,
  Modal,
  Tag,
  Typography,
  theme,
} from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { NavMenu } from '../Menu';
import { doc, getFirestore, updateDoc } from '@firebase/firestore';
import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import './Folios.scss';
import { Controller, useForm } from 'react-hook-form';
import { useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { EditOutlined } from '@ant-design/icons';
import { useAuth } from 'src/context/authContext';

export const Folio = () => {
  const { id } = useParams();
  const db = getFirestore();
  const navigate = useNavigate();
  const location = useLocation();
  const { Text } = Typography;

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [isFolioModalOpen, setIsFolioModalOpen] = useState(false);

  const showFolioModal = (register) => {
    setIsFolioModalOpen(true);
  };

  const handleCancel = () => {
    setIsFolioModalOpen(false);
  };

  const handleAceptedStatus = async () => {
    const foliosDocRef = doc(db, 'folios', location.state.folio.id);
    const usersDocRef = doc(db, 'users', location.state.user.id);

    await updateDoc(foliosDocRef, { status: 'Aceptado', motivo_rechazo: null, archived: false });

    await updateDoc(usersDocRef, {
      folios_aceptados: location.state.user.folios_aceptados + 1,
      folios_revision: location.state.user.folios_revision - 1,
    }).then(() => {
      navigate(-1);
    });
  };

  const handleAceptedStatus2 = async () => {
    const foliosDocRef = doc(db, 'folios', location.state.folio.id);
    const usersDocRef = doc(db, 'users', location.state.user.id);

    await updateDoc(foliosDocRef, { status: 'Aceptado', motivo_rechazo: null, archived: false });

    await updateDoc(usersDocRef, {
      folios_aceptados: location.state.user.folios_aceptados + 1,
      folios_rechazados: location.state.user.folios_rechazados - 1,
    }).then(() => {
      navigate(-1);
    });
  };

  const handleRejectedStatus = async (data) => {
    const foliosDocRef = doc(db, 'folios', location.state.folio.id);
    const usersDocRef = doc(db, 'users', location.state.user.id);

    await updateDoc(foliosDocRef, {
      status: 'Rechazado',
      motivo_rechazo: data.motivo_rechazo,
    });

    await updateDoc(usersDocRef, {
      folios_rechazados: location.state.user.folios_rechazados + 1,
      folios_revision: location.state.user.folios_revision - 1,
    }).then(() => {
      navigate(-1);
    });
  };

  const handleRejectedStatus2 = async (data) => {
    const foliosDocRef = doc(db, 'folios', location.state.folio.id);
    const usersDocRef = doc(db, 'users', location.state.user.id);

    await updateDoc(foliosDocRef, {
      status: 'Rechazado',
      motivo_rechazo: data.motivo_rechazo,
    });

    await updateDoc(usersDocRef, {
      folios_rechazados: location.state.user.folios_rechazados + 1,
      folios_aceptados: location.state.user.folios_aceptados - 1,
    }).then(() => {
      navigate(-1);
    });
  };
  // console.log('data ', location.state);

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
      }}
    >
      <div>
        <NavMenu />
        {location.state.folio !== null ? (
          <div className='card-container'>
            <Card>
              <div className='App'>
                <ConfigProvider
                  theme={{
                    components: {
                      Button: {
                        colorPrimary: '#CE0A2C',
                        colorPrimaryHover: '#FD2D2E',
                        colorPrimaryActive: '#CE142C',
                      },
                    },
                  }}
                >
                  <Button
                    type='primary'
                    ghost
                    size='large'
                    shape='circle'
                    icon={<ArrowLeftOutlined />}
                    onClick={() => navigate(-1)}
                  />
                </ConfigProvider>
                <div className='block'>
                  <h1>
                    Folio
                    <b style={{ fontSize: '2rem', marginLeft: '0.5rem' }}>
                      #{location.state.folio.folio}
                    </b>
                  </h1>
                  <Tag
                    color={
                      location.state.folio.status === 'Aceptado'
                        ? 'green-inverse'
                        : location.state.folio.status === 'Rechazado'
                        ? 'red-inverse'
                        : 'orange-inverse'
                    }
                  >
                    {location.state.folio.status}
                  </Tag>
                  {location.state.folio.archived ? (
                    <Tag color='volcano-inverse' style={{ marginLeft: '0' }}>
                      <DeleteOutlined /> ARCHIVADO
                    </Tag>
                  ) : null}

                  {location.state.folio.motivo_rechazo !== null ? (
                    <p>
                      <b style={{ marginRight: '0.5rem' }}>
                        <u>Motivo:</u>
                      </b>
                      {location.state.folio.motivo_rechazo}{' '}
                    </p>
                  ) : ""}
                </div>
              </div>
              <div className='img-container'>
                <img
                  src={location.state.folio.photo}
                  alt='imagen gonher'
                  className='imagen-pequena'
                />
              </div>
              <div className='button-container'>
                <ConfigProvider
                  theme={{
                    components: {
                      Button: {
                        colorPrimary: '#CE0A2C',
                        colorPrimaryHover: '#FD2D2E',
                        colorPrimaryActive: '#CE142C',
                      },
                    },
                  }}
                >
                  <Button
                    // className='boton'
                    type='primary'
                    disabled={
                      location.state.folio.status === 'Aceptado'
                    }
                    onClick={location.state.folio.status === "Revision pendiente" ? handleAceptedStatus : handleAceptedStatus2}
                  >
                    Aceptar
                  </Button>
                </ConfigProvider>
                <Button
                  className='boton'
                  type='default'
                  disabled={location.state.folio.archived || location.state.folio.status === 'Rechazado'}
                  onClick={() => showFolioModal()}
                >
                  Rechazar
                </Button>
              </div>
            </Card>
            <Modal
              title='Folio Rechazado'
              open={isFolioModalOpen}
              onOk={location.state.folio.status === "Revision pendiente" ? handleSubmit(handleRejectedStatus) : handleSubmit(handleRejectedStatus2)}
              onCancel={handleCancel}
            >
              <Text>Escriba el motivo del rechazo del folio</Text>
              <form>
                <Controller
                  name='motivo_rechazo'
                  control={control}
                  rules={{
                    required: 'Este campo es obligatorio.',
                  }}
                  render={({ field }) => (
                    <div style={{ marginTop: '1rem' }}>
                      <Input
                        {...field}
                        type='string'
                        placeholder='Motivo del rechazo del folio.'
                        prefix={<EditOutlined />}
                      />
                      <ErrorMessage
                        errors={errors}
                        name='motivo_rechazo'
                        render={({ message }) => (
                          <Text type='danger'>{message}</Text>
                        )}
                      />
                    </div>
                  )}
                />
              </form>
            </Modal>
          </div>
        ) : (
          'no data'
        )}
      </div>
    </ConfigProvider>
  );
};
