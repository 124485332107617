import { useNavigate } from 'react-router-dom';
import {
  doCreateUserEmailPassword,
  doSignInWithEmailAndPassword,
} from 'src/firebase/auth';
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Input,
  Button,
  Typography,
  Alert,
  Row,
  Col,
  Select,
  ConfigProvider,
  theme,
} from 'antd';
import {
  UserOutlined,
  LockOutlined,
  EyeInvisibleFilled,
  EyeOutlined,
  RedEnvelopeOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
  ManOutlined,
  IdcardOutlined,
  NumberOutlined,
} from '@ant-design/icons';
import { useAuth } from 'src/context/authContext';
import { Link, Navigate } from 'react-router-dom';
import { ErrorMessage } from '@hookform/error-message';
import { addDoc, collection, getFirestore } from '@firebase/firestore';
import './RegistroUsuarios.scss';

export const RegistroUsuarios = () => {
  const { Title, Text } = Typography;

  const navigate = useNavigate();
  const db = getFirestore();

  const [isRegistering, setIsRegistering] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [clientGonher, setClientGonher] = useState(null);
  const [nickname, setNickname] = useState('');

  const { userLoggedIn } = useAuth();
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const randomNumber = Math.floor(Math.random() * 1000);
    const generatedNickname = data.name.split(' ')[0] + randomNumber;

    const fullName = data.name + ' ' + data.lastName;

    if (!isRegistering) {
      const userData = {
        ...data,
        nickname: generatedNickname,
        fullName: fullName,
        role: 'user',
        folios: 0,
        folios_aceptados: 0,
        folios_rechazados: 0,
        folios_revision: 0,
      };
      setIsRegistering(true);
      await doCreateUserEmailPassword(data.email, data.password)
        .then((res) => {
          addDoc(collection(db, 'users'), userData);
        })
        .catch((err) => {
          setErrorMessage(true);
          setIsRegistering(false);
        });
    }
  };

  // useEffect(() => {
  //   if (userLoggedIn) {
  //     // setIsRegistering(true);
  //     navigate('/mis-folios');
  //   }
  // }, [userLoggedIn]);

  const handleClientChange = (value) => {
    if (value === 'cliente gonher') {
      setClientGonher(true);
      reset((formValues) => ({
        ...formValues,
        client_data: '',
        client: value,
      }));
    } else {
      setClientGonher(false);
      reset((formValues) => ({
        ...formValues,
        client_data: '',
        client: value,
      }));
    }
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
      }}
    >
      <div className='registro-container'>
        {userLoggedIn && <Navigate to={'/'} replace={true} />}
        <h1>Registra tu cuenta</h1>
        <div className='form-container'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row style={{ marginBottom: '0.5rem' }}>
              <Col span={24} sm={{ span: 12 }} style={{ padding: '0 2rem' }}>
                <Controller
                  name='name'
                  control={control}
                  rules={{
                    required: 'Este campo es obligatorio.',
                  }}
                  render={({ field }) => (
                    <div>
                      <Input
                        {...field}
                        className='login-email'
                        type='text'
                        placeholder='Nombre'
                        prefix={<UserOutlined />}
                      />
                      <ErrorMessage
                        errors={errors}
                        name='name'
                        render={({ message }) => (
                          <Text type='danger'>{message}</Text>
                        )}
                      />
                    </div>
                  )}
                />
              </Col>
              <Col span={24} sm={{ span: 12 }} style={{ padding: '0 2rem' }}>
                <Controller
                  name='lastName'
                  control={control}
                  rules={{
                    required: 'Este campo es obligatorio.',
                  }}
                  render={({ field }) => (
                    <div>
                      <Input
                        {...field}
                        className='login-email'
                        type='text'
                        placeholder='Apellido'
                        prefix={<UserOutlined />}
                      />
                      <ErrorMessage
                        errors={errors}
                        name='lastName'
                        render={({ message }) => (
                          <Text type='danger'>{message}</Text>
                        )}
                      />
                    </div>
                  )}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: '0.5rem' }}>
              <Col span={24} style={{ padding: '0 2rem' }}>
                <Controller
                  name='address'
                  control={control}
                  rules={{
                    required: 'Este campo es obligatorio.',
                  }}
                  render={({ field }) => (
                    <div>
                      <Input
                        {...field}
                        className='login-email'
                        type='text'
                        placeholder='Dirección completa'
                        prefix={<EnvironmentOutlined />}
                      />
                      <ErrorMessage
                        errors={errors}
                        name='address'
                        render={({ message }) => (
                          <Text type='danger'>{message}</Text>
                        )}
                      />
                    </div>
                  )}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: '0.5rem' }}>
              <Col span={24} sm={{ span: 8 }} style={{ padding: '0 2rem' }}>
                <Controller
                  name='phone'
                  control={control}
                  rules={{
                    required: 'Este campo es obligatorio.',
                    minLength: {
                      value: 10,
                      message: 'Mínimo 8 caracteres.',
                    },
                    maxLength: {
                      value: 12,
                      message: 'Incluyendo LADA máximo 12 caracteres.',
                    },
                  }}
                  render={({ field }) => (
                    <div>
                      <Input
                        {...field}
                        className='login-email'
                        type='number'
                        placeholder='8130303000'
                        prefix={<PhoneOutlined />}
                      />
                      <ErrorMessage
                        errors={errors}
                        name='phone'
                        render={({ message }) => (
                          <Text type='danger'>{message}</Text>
                        )}
                      />
                    </div>
                  )}
                />
              </Col>
              <Col span={24} sm={{ span: 8 }} style={{ padding: '0 2rem' }}>
                <Controller
                  name='gender'
                  control={control}
                  rules={{
                    required: 'Este campo es obligatorio.',
                  }}
                  render={({ field }) => (
                    <div>
                      <Select
                        {...field}
                        style={{
                          width: '100%',
                        }}
                        options={[
                          {
                            value: 'femenino',
                            label: 'Femenino',
                          },
                          {
                            value: 'masculino',
                            label: 'Masculino',
                          },
                          {
                            value: 'genero-indefinido',
                            label: 'Prefiero no decirlo',
                          },
                        ]}
                        className='login-email'
                        type='text'
                        placeholder='Género'
                        prefix={<ManOutlined />}
                      />
                    </div>
                  )}
                />
              </Col>
              <Col span={24} sm={{ span: 8 }} style={{ padding: '0 2rem' }}>
                <Controller
                  name='age'
                  control={control}
                  rules={{
                    required: 'Este campo es obligatorio.',
                  }}
                  render={({ field }) => (
                    <div>
                      <Input
                        {...field}
                        className='login-email'
                        type='number'
                        placeholder='Edad'
                        prefix={<IdcardOutlined />}
                      />
                      <ErrorMessage
                        errors={errors}
                        name='age'
                        render={({ message }) => (
                          <Text type='danger'>{message}</Text>
                        )}
                      />
                    </div>
                  )}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: '0.5rem' }}>
              <Col
                span={24}
                sm={{ span: 12 }}
                style={{ padding: '0 2rem', marginBottom: '.5rem' }}
              >
                <Controller
                  name='client'
                  control={control}
                  render={({ field }) => (
                    <div>
                      <Select
                        {...field}
                        style={{
                          width: '100%',
                        }}
                        defaultValue='Soy cliente Gonher'
                        onChange={handleClientChange}
                        options={[
                          {
                            value: 'cliente gonher',
                            label: 'Soy cliente Gonher',
                          },
                          {
                            value: 'negocio propio',
                            label: 'No soy cliente Gonher',
                          },
                        ]}
                      />
                    </div>
                  )}
                />
              </Col>
              <Col span={24} sm={{ span: 12 }} style={{ padding: '0 2rem' }}>
                <Controller
                  name='client_data'
                  control={control}
                  rules={{
                    required: 'Este campo es obligatorio.',
                  }}
                  render={({ field }) => (
                    <div>
                      <Input
                        {...field}
                        className='login-email'
                        type={clientGonher ? 'number' : 'text'}
                        disabled={clientGonher === null}
                        placeholder={
                          clientGonher
                            ? 'No. de Cliente'
                            : 'Nombre de su negocio'
                        }
                        prefix={
                          clientGonher ? <NumberOutlined /> : <UserOutlined />
                        }
                      />
                      <ErrorMessage
                        errors={errors}
                        name='client'
                        render={({ message }) => (
                          <Text type='danger'>{message}</Text>
                        )}
                      />
                    </div>
                  )}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: '0.5rem' }}>
              <Col span={24} sm={{ span: 12 }} style={{ padding: '0 2rem' }}>
                <Controller
                  name='email'
                  control={control}
                  rules={{
                    required: 'Este campo es obligatorio.',
                    pattern: {
                      value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: 'Formato incorrecto para el correo.',
                    },
                  }}
                  render={({ field }) => (
                    <div>
                      <Input
                        {...field}
                        className='login-email'
                        type='email'
                        placeholder='email@ejemplo.com'
                        prefix={<RedEnvelopeOutlined />}
                      />
                      <ErrorMessage
                        errors={errors}
                        name='email'
                        render={({ message }) => (
                          <Text type='danger'>{message}</Text>
                        )}
                      />
                    </div>
                  )}
                />
              </Col>
              <Col span={24} sm={{ span: 12 }} style={{ padding: '0 2rem' }}>
                <Controller
                  name='password'
                  control={control}
                  rules={{
                    minLength: {
                      value: 8,
                      message: 'Mínimo 8 caracteres.',
                    },
                    maxLength: {
                      value: 16,
                      message: 'Máximo 16 caracteres.',
                    },
                    required: 'Este campo es obligatorio.',
                  }}
                  render={({ field }) => (
                    <div>
                      <Input
                        {...field}
                        className='login-pass'
                        placeholder='Contraseña'
                        type={showPass ? 'string' : 'password'}
                        prefix={<LockOutlined />}
                        suffix={
                          showPass ? (
                            <EyeOutlined
                              onClick={() => setShowPass(!showPass)}
                            />
                          ) : (
                            <EyeInvisibleFilled
                              onClick={() => setShowPass(!showPass)}
                            />
                          )
                        }
                      />
                      <ErrorMessage
                        errors={errors}
                        name='password'
                        render={({ message }) => (
                          <Text type='danger'>{message}</Text>
                        )}
                      />
                    </div>
                  )}
                />
              </Col>
            </Row>

            <Row style={{ justifyContent: 'center' }}>
              <Col span={8} style={{ textAlign: 'center' }}>
                <ConfigProvider
                  theme={{
                    components: {
                      Button: {
                        colorPrimary: '#CE0A2C',
                        colorPrimaryHover: '#FD2D2E',
                        colorPrimaryActive: '#CE142C',
                      },
                    },
                  }}
                >
                  <Button
                    type='primary'
                    htmlType='submit'
                    shape='round'
                    block
                    style={{ margin: '2rem auto', width: 'auto' }}
                    disabled={isRegistering}
                  >
                    {isRegistering ? 'Registrando...' : 'Registrarme'}
                  </Button>
                </ConfigProvider>

                <div>
                  <Link to={'/'} style={{ textDecoration: 'none' }}>
                    ¿Ya tienes cuenta con nosotros?
                    <u style={{ marginLeft: '0.5rem' }}>Inicia sesión</u>
                  </Link>
                </div>
              </Col>
            </Row>
          </form>
          {errorMessage && (
            <Alert
              style={{
                textAlign: 'left',
              }}
              message='Error'
              description='Ocurrió un error. Favor de volver a intentarlo.'
              type='error'
              showIcon
              closable
              onClick={() => setErrorMessage(false)}
            />
          )}
        </div>
      </div>
    </ConfigProvider>
  );
};
