import {
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
} from '@firebase/firestore';
import { Button, ConfigProvider, Table, Tag, theme } from 'antd';
import {
  UserOutlined,
  DeleteOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const DetalleFolios = ({ email }) => {
  const navigate = useNavigate();
  const db = getFirestore();

  const [foliosList, setFoliosList] = useState([]);
  const [userFolios, setUserFolios] = useState([]);
  const [userData, setUserData] = useState([]);

  const columnsList = [
    {
      title: 'Folios',
      dataIndex: 'folio',
      key: 'folio',
      width: 300,
      filters: [
        {
          text: 'Activos',
          value: 'Activos',
        },
        {
          text: 'Archivados',
          value: 'Archivados',
        },
      ],
      render: (folio, row) => (
        <>
          {folio}
          {row.archived === true ? (
            <Tag color='volcano-inverse' style={{ marginLeft: '1rem' }}>
              <DeleteOutlined /> ARCHIVADO
            </Tag>
          ) : null}
        </>
      ),
      onFilter: (value, record) => {
        return value === 'Archivados' ? record.archived : !record.archived;
      },
    },
    {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'date',
      width: 200,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 300,
      filters: [
        {
          text: 'Revision pendiente',
          value: 'Revision pendiente',
        },
        {
          text: 'Aceptado',
          value: 'Aceptado',
        },
        {
          text: 'Rechazado',
          value: 'Rechazado',
        },
      ],
      render: (_, { status }) => (
        <>
          <Tag
            color={
              status === 'Aceptado'
                ? 'green-inverse'
                : status === 'Rechazado'
                ? 'red-inverse'
                : 'orange-inverse'
            }
          >
            {status}
          </Tag>
          {status === 'Aceptado' ? '' : ''}
        </>
      ),
      onFilter: (value, record) => record.status.includes(value),
    },
  ];

  const getFoliosData = async () => {
    const valRef = collection(db, 'folios');
    const dataDb = await getDocs(valRef);
    const allData = dataDb.docs.map((val) => ({ ...val.data(), id: val.id }));
    setFoliosList(allData);
  };

  const getUserData = async () => {
    // New approach
    const dataDbTest = collection(db, 'users');
    const q = query(dataDbTest, where('email', '==', email));
    const doc_refs = await getDocs(q);

    const tempArr = [];
    doc_refs.forEach((doc) => {
      tempArr.push({ ...doc.data(), id: doc.id });
    });
    setUserData(tempArr[0]);
  };

  useEffect(() => {
    if (foliosList.length !== 0) {
      const userFoliosArr = foliosList.filter(
        (item) => item.user_email === email
      );
      setUserFolios(userFoliosArr);
    }
  }, [foliosList]);

  useEffect(() => {
    getFoliosData();
    getUserData();
  }, []);
  // console.log('state?? ', userData);

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
      }}
    >
      <div
        style={{
          width: '70%',
          margin: '3rem auto 0 auto',
          overflowX: 'auto',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '1rem',
          }}
        >
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorPrimary: '#CE0A2C',
                  colorPrimaryHover: '#FD2D2E',
                  colorPrimaryActive: '#CE142C',
                },
              },
            }}
          >
            <Button
              type='primary'
              ghost
              size='large'
              shape='circle'
              icon={<ArrowLeftOutlined />}
              onClick={() => navigate('/admin')}
            />
          </ConfigProvider>
          <div style={{ marginLeft: '2rem' }}>
            <h1>Folios registrados</h1>
            <p>
              <UserOutlined style={{ marginRight: '0.5rem' }} />
              {email}
            </p>
          </div>
        </div>
        {/* <ConfigProvider
        theme={{
          algorithm: theme.darkAlgorithm,
        }}
      > */}
        <Table
          className='table'
          size='middle'
          bordered
          pagination={false}
          dataSource={userFolios}
          columns={columnsList}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                navigate(`/folio/${rowIndex}`, {
                  state: { folio: record, user: userData },
                });
              },
            };
          }}
        />
      </div>
    </ConfigProvider>
  );
};
