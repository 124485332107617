import { useEffect, useState } from 'react';
import { NavMenu } from '../Menu';
import './topTen.scss';
import trophyFirst from '../../img/first.png';
import trophySecond from '../../img/second.png';
import trophyThird from '../../img/third.png';
import podium from '../../img/podium.png';
import flag from '../../img/flag.png';
import bigTrophy from '../../img/big-trophy.png';
import { collection, getDocs, getFirestore } from '@firebase/firestore';
import { JustTop10 } from './just-top-10';

export const Top10 = () => {
  const db = getFirestore();
  const [data, setData] = useState([]);
  const [userList, setUserList] = useState([]);

  const fetchUserDocs = async () => {
    const querySnapshot = await getDocs(collection(db, 'users'));
    const tempArr = [];
    querySnapshot.forEach((doc) => {
      tempArr.push({ ...doc.data(), id: doc.id });
    });
    const usersTable = tempArr.filter((i) => i.role === 'user');
    setUserList(usersTable);
  };

  useEffect(() => {
    // Función para ordenar de forma ascendente
    const sortedData = userList.sort(
      (a, b) => b.folios_aceptados - a.folios_aceptados
    );
    // sortedData.shift();
    // sortedData.splice(2,1);
    
    setData(sortedData);
  }, [userList]);

  useEffect(() => {
    fetchUserDocs();
  }, []);

  return (
    <div style={{ overflow: 'hidden' }}>
      <NavMenu />
      <div className='container'>
        <div className='hero'>TOP 10</div>
        <div className='header'>Conoce el RANKING</div>
        <div className='top-3'>
          <img className='big-trophy' src={bigTrophy} alt='Trofeo' />
          <img className='podium' src={podium} alt='Podium' />
          <img className='flag' src={flag} alt='Bandera' />
          {data.slice(0, 10).map(
            (item, index) => (
              // index <= 2 && (
              <div
                className={`table-row ${
                  index === 0
                    ? 'first'
                    : index === 1
                    ? 'second'
                    : index === 2
                    ? 'third'
                    : 'all'
                }`}
                key={item.id}
              >
                <div>
                  <span>
                    <span>•</span> {index + 1}.
                  </span>
                  <span className='nombres'> {item.nickname}</span>
                </div>
                <div>
                  <span>{item.folios_aceptados}</span>
                  {index < 3 ? (
                    <span style={{ marginLeft: '1rem' }}>
                      <img
                        src={
                          index === 0
                            ? trophyFirst
                            : index === 1
                            ? trophySecond
                            : trophyThird
                        }
                        alt={
                          index === 0
                            ? 'Primer Lugar'
                            : index === 1
                            ? 'Segundo Lugar'
                            : 'Tercer Lugar'
                        }
                      />
                    </span>
                  ) : null}
                </div>
              </div>
            )
            // )
          )}
        </div>
      </div>
    </div>
  );
};
