import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Input,
  Button,
  Typography,
  Alert,
  Row,
  Col,
  ConfigProvider,
  theme,
} from "antd";
import {
  UserOutlined,
  LockOutlined,
  EyeInvisibleFilled,
  EyeOutlined,
} from "@ant-design/icons";
import { Toaster, toast } from "sonner";
import "./CambioContrasena.scss";
import {
  doPasswordReset,
  doSignInWithEmailAndPassword,
} from "src/firebase/auth";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from 'src/context/authContext';


function useQuery() {
    return new URLSearchParams(useLocation().search)
}

export const CambioContrasena = () => {
  const navigate = useNavigate();
  const { resetPassword } = useAuth();
  const query = useQuery();

  const { Title, Text } = Typography;
  const [isSignIn, setIsSignIn] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [passwordState, setPassword] = useState('');
  const [recoveryPass, setRecoveryPass] = useState(false);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const watchPass = watch(['password']);

  const handleChangePassword = async ({ password }) => {
      await resetPassword(query.get('oobCode'), password);
      toast.success("Su contraseña ha sido reestablecida");
      navigate('/');
    };
    // console.log('enter to change', watchPass[0]);

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
      }}
    >
      <Row className="back">
        <Col span={24} className="login">
          <div className="login-container">
            <div className="form-container">
            <Title level={2}>Ingresa tu nueva contraseña</Title>
              <form>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      className="login-email"
                      type={showPass ? "string" : "password"}
                      placeholder="Nueva contraseña"
                      prefix={<LockOutlined />}
                    //   onChange={e => setPassword(e.target.value)}
                      suffix={
                        showPass ? (
                          <EyeOutlined
                            onClick={() => setShowPass(!showPass)}
                          />
                        ) : (
                          <EyeInvisibleFilled
                            onClick={() => setShowPass(!showPass)}
                          />
                        )
                      }
                    />
                  )}
                />
                  <ConfigProvider
                    theme={{
                      components: {
                        Button: {
                          colorPrimary: "#CE0A2C",
                          colorPrimaryHover: "#FD2D2E",
                          colorPrimaryActive: "#CE142C",
                        },
                      },
                    }}
                  >
                    <Button
                      type="primary"
                      onClick={handleSubmit(handleChangePassword)}
                      shape="round"
                      disabled={watchPass[0] === undefined}
                      size="large"
                      style={{ width: "60%", marginTop: "2rem" }}
                    >
                      Cambiar mi contraseña
                    </Button>
                  </ConfigProvider>
              </form>
            </div>
          </div>
        </Col>
      </Row>
      <Toaster position="bottom-center" richColors />
    </ConfigProvider>
  );
};
