import { Routes, Route } from 'react-router-dom';
import { Login } from './components/Login';
import { Admin } from './components/Admin';
import { DetalleUsuario } from './components/Admin/DetalleUsuario';
import { Registro, RegistroUsuarios } from './components/RegistroUsuarios';
import { Top10 } from './components/Top10';
import { Usuario } from './components/Usuario';
import { AuthProvider } from './context/authContext';
import { DetalleFolios } from './components/Folios/DetalleFolios';
import { Folio } from './components/Folios/Folio';
import { CambioContrasena } from './components/CambioContrasena';
import { JustTop10 } from './components/Top10/just-top-10';

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/admin' element={<Admin />} />
        <Route path='/detalle-usuario/:id' element={<DetalleUsuario />} />
        <Route path='/detalle-folio/:id' element={<DetalleFolios />} />
        <Route path='/folio/:id' element={<Folio />} />
        <Route path='/registro-usuarios' element={<RegistroUsuarios />} />
        <Route path='/top-10' element={<Top10 />} />
        <Route path='/just-top-10' element={<JustTop10 />} />
        <Route path='/mis-folios' element={<Usuario />} />
        <Route path='/cambio-contrasena' element={<CambioContrasena />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
