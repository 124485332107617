import { Badge, Button, ConfigProvider, Table, theme } from 'antd';
import './Admin.scss';
import { useNavigate } from 'react-router-dom';
import { NavMenu } from '../Menu';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import ColumnGroup from 'antd/es/table/ColumnGroup';
import Column from 'antd/es/table/Column';

export const Admin = () => {
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [isPendingActive, setIsPendingActive] = useState(false);

  const db = getFirestore();

  const fetchUserDocs = async () => {
    const querySnapshot = await getDocs(collection(db, 'users'));
    const tempArr = [];
    querySnapshot.forEach((doc) => {
      tempArr.push({ ...doc.data(), id: doc.id });
    });
    const usersTable = tempArr.filter((i) => i.role === 'user');
    setUserList(usersTable);
  };

  useEffect(() => {
    fetchUserDocs();
  }, []);

  return (
    <div>
      <NavMenu />
      <div className='admin-container'>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '4rem',
          }}
        >
          <h1>Lista de usuarios</h1>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorPrimary: '#CE0A2C',
                  colorPrimaryHover: '#FD2D2E',
                  colorPrimaryActive: '#CE142C',
                },
              },
            }}
          >
            <Button
              type='primary'
              shape='round'
              // icon={<PlusOutlined />}
              size='middle'
              iconPosition='end'
              style={{
                marginRight: '2rem',
              }}
              onClick={() => setIsPendingActive(!isPendingActive)}
            >
              {isPendingActive ? 'Ver Todos' : 'Ver Pendientes'}
            </Button>
          </ConfigProvider>
        </div>
        <ConfigProvider
          theme={{
            algorithm: theme.darkAlgorithm,
          }}
        >
          <Table
            className='table'
            size='middle'
            bordered
            pagination={false}
            dataSource={userList}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  navigate(`/detalle-usuario/${record.id}`, {
                    state: record,
                  });
                },
              };
            }}
          >
            <Column title='Nombre' dataIndex={'fullName'} key='name' />

            <Column title='Correo' dataIndex='email' key='email' />
            <ColumnGroup title='Productos registrados' align='left'>
              {isPendingActive ? (
                <Column
                  title={
                    <div>
                      <Badge status='processing' text='Pendientes' />
                    </div>
                  }
                  dataIndex='folios_revision'
                  key='folios_revision'
                  sorter={(a, b) => a.folios_revision - b.folios_revision}
                />
              ) : (
                <>
                  <Column
                    title={
                      <div>
                        <Badge status='processing' text='Pendientes' />
                      </div>
                    }
                    dataIndex='folios_revision'
                    key='folios_revision'
                    sorter={(a, b) => a.folios_revision - b.folios_revision}
                  />
                  <Column
                    title={
                      <div>
                        <Badge status='error' text='Rechazados' />
                      </div>
                    }
                    dataIndex='folios_rechazados'
                    key='folios_rechazados'
                    sorter={(a, b) => a.folios_rechazados - b.folios_rechazados}
                  />
                  <Column
                    title={
                      <div>
                        <Badge status='success' text='Aceptados' />
                      </div>
                    }
                    dataIndex='folios_aceptados'
                    key='folios_aceptados'
                    sorter={(a, b) => a.folios_aceptados - b.folios_aceptados}
                  />
                  <Column
                    title={<u>Total</u>}
                    dataIndex='folios'
                    key='folios'
                    sorter={(a, b) => a.folios - b.folios}
                  />
                </>
              )}
            </ColumnGroup>
          </Table>
        </ConfigProvider>
      </div>
    </div>
  );
};
