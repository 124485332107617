import { useLocation, useParams } from 'react-router-dom';
import { NavMenu } from '../Menu';
import { DetalleFolios } from '../Folios/DetalleFolios';

export const DetalleUsuario = () => {
  // let { id } = useParams();
  let { state } = useLocation();
  const { email } = state;
  return (
    <div>
      <NavMenu />
      <DetalleFolios email={email} />
    </div>
  );
};
