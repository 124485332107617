import {
  Button,
  Table,
  Tag,
  Tooltip,
  Modal,
  Input,
  Typography,
  Alert,
  ConfigProvider,
  theme,
} from 'antd';
import './Usuario.scss';
import {
  InfoCircleOutlined,
  PlusOutlined,
  NumberOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { NavMenu } from '../Menu';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
} from '@firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { imgDB } from 'src/firebase/firebase';
import { v4 } from 'uuid';
import { useAuth } from 'src/context/authContext';
import { useNavigate } from 'react-router-dom';

export const Usuario = () => {
  const columnsList = [
    {
      title: 'Folios',
      dataIndex: 'folio',
      key: 'folio',
      width: 200,
      filters: [
        {
          text: 'Activos',
          value: 'Activos',
        },
        {
          text: 'Archivados',
          value: 'Archivados',
        },
      ],
      render: (folio, row) => (
        <div style={{ display: 'flex' }}>
          {folio}
          {row.archived === true ? (
            <Tag color='volcano-inverse' style={{ marginLeft: '1rem' }}>
              <DeleteOutlined /> ARCHIVADO
            </Tag>
          ) : null}
        </div>
      ),
      onFilter: (value, record) => {
        return value === 'Archivados' ? record.archived : !record.archived;
      },
    },
    {
      title: 'Fecha de registro',
      dataIndex: 'date',
      width: 200,
      key: 'date',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 200,
      filters: [
        {
          text: 'Revision pendiente',
          value: 'Revision pendiente',
        },
        {
          text: 'Aceptado',
          value: 'Aceptado',
        },
        {
          text: 'Rechazado',
          value: 'Rechazado',
        },
      ],
      render: (status, row) => (
        <>
          <Tag
            color={
              status === 'Aceptado'
                ? 'green-inverse'
                : status === 'Rechazado'
                ? 'red-inverse'
                : 'orange-inverse'
            }
          >
            {status}
          </Tag>
          {status === 'Rechazado' && !row.archived ? (
            <Tooltip title='Favor de volver a registrar su folio. Click aquí para más información.'>
              <InfoCircleOutlined />
            </Tooltip>
          ) : (
            ''
          )}
        </>
      ),
      onFilter: (value, record) => record.status.includes(value),
    },
    {
      title: 'Motivo de rechazo',
      dataIndex: 'motivo_rechazo',
      key: 'motivo_rechazo',
      width: 350,
    },
  ];
  const { Text } = Typography;
  const db = getFirestore();
  const navigate = useNavigate();
  const userLocal = JSON.parse(localStorage.getItem('user'));

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFolioModalOpen, setIsFolioModalOpen] = useState(false);
  const [registerToDelete, setRegisterToDelete] = useState({});
  const [preview, setPreview] = useState('');
  const [img, setImg] = useState('');
  const [userFolios, setUserFolios] = useState([]);
  const [folioValidated, setFolioValidated] = useState(false);
  const [showValidatingError, setShowValidatingError] = useState(false);
  const [showValidatingErrorEmpty, setShowValidatingErrorEmpty] =
  useState(false);
  const [isPhotoUp, setIsPhotoUp] = useState(false);
  const [userData, setUserData] = useState(userLocal);
  
  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const watchFolio = watch(['folio']);

  const showModal = (register) => {
    setRegisterToDelete(register);
    setIsModalOpen(true);
  };

  const showFolioModal = (register) => {
    setIsFolioModalOpen(true);
  };

  const handleOk = () => {
    deleteFolio(registerToDelete.id);
    getFoliosData();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancelFolioModal = () => {
    reset({
      folio: '',
      photo: null,
    });
    setPreview('');
    setIsFolioModalOpen(false);
    setFolioValidated(false);
    setShowValidatingError(false);
    setIsPhotoUp(false);
  };

  const handleUploadedFile = (event) => {
    const file = event.target.files[0];
    const urlImage = URL.createObjectURL(file);

    setPreview(urlImage);

    const folioImgsRef = ref(imgDB, `folioImgs/${v4()}`);

    uploadBytes(folioImgsRef, file).then((data) => {
      getDownloadURL(data.ref).then((val) => {
        setImg(val);
        setIsPhotoUp(true);
      });
    });
  };

  const closeErrorFolioEmpty = () => {
    setShowValidatingError(false);
    setShowValidatingErrorEmpty(false);
  };

  const handleFolioValidate = () => {
    if (watchFolio[0] === '') {
      setShowValidatingErrorEmpty(true);
    } else {
      const matchF = userFolios.find(
        (folioItem) => folioItem.folio === watchFolio[0]
      );

      if (
        matchF === undefined ||
        (matchF.user_email === userData.email && matchF.archived) ||
        (matchF.user_email !== userData.email && matchF.register_count <= 8)
      ) {
        setFolioValidated(true);
        setShowValidatingError(false);
      } else if (matchF.user_email === userData.email && !matchF.archived) {
        reset({
          folio: '',
          photo: null,
        });

        setShowValidatingError(true);
      }
    }
  };

  const handleFolioRegister = async (data) => {
    const folioObj = {
      ...data,
      photo: img,
      user_email: userData.email,
      status: 'Revision pendiente',
      date: new Date().toLocaleDateString(),
      archived: false,
      motivo_rechazo: null,
      register_count: 0,
    };

    await addDoc(collection(db, 'folios'), folioObj)
      .then(() => {
        getFoliosData();
        reset({ folio: '', photo: null });
        setPreview('');
        setIsFolioModalOpen(false);
        setFolioValidated(false);
        setIsPhotoUp(false);
      })
      .catch((e) => {
        console.log('There was some error on the upload for folio obj.');
      });
  };

  const getFoliosData = async () => {
    // New approach
    const dataDbTest = collection(db, 'folios');
    const q = query(dataDbTest, where('user_email', '==', userData.email));
    const doc_refs = await getDocs(q);

    const tempArr = [];
    doc_refs.forEach((doc) => {
      tempArr.push({ ...doc.data(), id: doc.id });
    });
    setUserFolios(tempArr);
  };

  const deleteFolio = async (id) => {
    const folioRef = doc(db, 'folios', id);

    updateDoc(folioRef, {
      archived: true,
    });
  };

  useEffect(() => {
    const usersDocRef = doc(db, 'users', userData.id);

    const foliosAceptados = [];
    const foliosRechazados = [];
    const foliosRevision = [];

    userFolios.forEach((ele) => {
      if (ele.status === 'Aceptado') {
        foliosAceptados.push(ele);
      } else if (ele.status === 'Rechazado') {
        foliosRechazados.push(ele);
      } else if (ele.status === 'Revision pendiente') {
        foliosRevision.push(ele);
      }
    });

    const userCountFolios = {
      folios:
        foliosAceptados.length +
        foliosRechazados.length +
        foliosRevision.length,
      folios_aceptados: foliosAceptados.length,
      folios_rechazados: foliosRechazados.length,
      folios_revision: foliosRevision.length,
    };

    updateDoc(usersDocRef, userCountFolios);
  }, [userFolios]);

  useEffect(() => {
    // console.log('info user>', userData)
    // Review if password is different than the one we had on the user obj, then update it
    // await updateDoc(usersDocRef, {
    //   password: password
    // });
    getFoliosData();
  }, []);

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
      }}
    >
      <div className='user-container'>
        <NavMenu />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h1>Registro de mis folios</h1>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorPrimary: '#CE0A2C',
                  colorPrimaryHover: '#FD2D2E',
                  colorPrimaryActive: '#CE142C',
                },
              },
            }}
          >
            <Button
              type='primary'
              shape='round'
              icon={<PlusOutlined />}
              size='middle'
              iconPosition='end'
              style={{
                marginRight: '2rem',
              }}
              onClick={() => showFolioModal()}
            >
              Agregar Folio
            </Button>
          </ConfigProvider>
        </div>
        <Table
          className='table'
          size='middle'
          bordered
          pagination={false}
          dataSource={userFolios}
          columns={columnsList}
          onRow={(record, rowIndex) => {
            if (record.status === 'Rechazado' && !record.archived) {
              return {
                onClick: () => showModal(record),
              };
            }
          }}
        />
        <Modal
          title='Folio rechazado'
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{ style: { background: '#CE0A2C' } }}
        >
          <Alert
            message='Recuerda que al eliminar tu folio, deberás tener contigo el
            producto para volver a registrarlo.'
            type='error'
          />
        </Modal>

        <Modal
          title='Registro de Folio'
          open={isFolioModalOpen}
          onOk={handleSubmit(handleFolioRegister)}
          onCancel={handleCancelFolioModal}
          okButtonProps={{
            disabled: !isPhotoUp,
            style: { background: '#CE0A2C' },
          }}
        >
          <form>
            <Controller
              name='folio'
              control={control}
              rules={{
                required: 'Este campo es obligatorio.',
                minLength: {
                  value: 8,
                  message: 'Mínimo 8 caracteres.',
                },
                maxLength: {
                  value: 16,
                  message: 'Máximo 16 caracteres.',
                },
              }}
              render={({ field }) => (
                <div>
                  <Input
                    {...field}
                    type='number'
                    placeholder='0001230392122345'
                    prefix={<NumberOutlined />}
                    // onChange={onInputChange}
                  />
                  <ErrorMessage
                    errors={errors}
                    name='folio'
                    render={({ message }) => (
                      <Text type='danger'>{message}</Text>
                    )}
                  />
                </div>
              )}
            />
            <div style={{ margin: '1rem', textAlign: 'center' }}>
              {showValidatingError ? (
                <Alert
                  style={{
                    marginBottom: '1rem',
                    textAlign: 'left',
                  }}
                  message='No puedes registrar el mismo folio.'
                  type='error'
                  showIcon
                  closable
                  onClose={closeErrorFolioEmpty}
                />
              ) : null}
              {showValidatingErrorEmpty ? (
                <Alert
                  style={{
                    marginBottom: '1rem',
                    textAlign: 'left',
                  }}
                  message='Favor de ingresar datos para validar su folio.'
                  type='error'
                  closable
                  onClose={closeErrorFolioEmpty}
                />
              ) : null}
              {!folioValidated && (
                <ConfigProvider
                  theme={{
                    components: {
                      Button: {
                        colorPrimary: '#CE0A2C',
                        colorPrimaryHover: '#FD2D2E',
                        colorPrimaryActive: '#CE142C',
                      },
                    },
                  }}
                >
                  <Button
                    type='primary'
                    shape='round'
                    disabled={
                      watchFolio[0] === undefined || showValidatingError
                    }
                    onClick={handleFolioValidate}
                  >
                    Validar mi código
                  </Button>
                </ConfigProvider>
              )}
            </div>
            <Controller
              name='photo'
              // id="photo"
              control={control}
              // rules={{
              //   required: "Este campo es obligatorio.",
              // }}
              render={({ field: { ref } }) => (
                <div>
                  <Input
                    type='file'
                    prefix={<NumberOutlined />}
                    disabled={!folioValidated || watchFolio.includes('')}
                    ref={ref}
                    accept='image/png, image/jpeg, imgage/jpg'
                    onChange={(e) => {
                      handleUploadedFile(e);
                    }}
                  />
                  <ErrorMessage
                    errors={errors}
                    name='photo'
                    render={({ message }) => (
                      <Text type='danger'>{message}</Text>
                    )}
                  />
                </div>
              )}
            />
            {!folioValidated && (
              <span
                style={{
                  color: 'gray',
                  marginLeft: '0.5rem',
                }}
              >
                <i>
                  *Este campo se habilitará una vez que ingrese su no. de folio.
                </i>
              </span>
            )}
            {preview !== '' ? (
              <img src={preview} style={{ width: '100%', height: '100%' }} />
            ) : (
              ''
            )}
          </form>
        </Modal>
      </div>
    </ConfigProvider>
  );
};
